'use client'

import { Tab, TabList } from '@ariakit/react'
import React from 'react'
import styled, { css } from 'styled-components'

import { ResponsiveImage, fromMd, untilMd } from '@syconium/little-miss-figgy'
import {
  TypeStyle,
  TypeStyleBodyNano,
  TypeStyleCssFunctionProps,
} from '@syconium/little-miss-figgy/dist/components/TypeStyle'

import { Tile } from '../../../tiles/Tiles.client'
import {
  sectionSideGutterFromMdAndExpanded,
  sectionSideGutterUntilMd,
} from '../../PageSection.client'

import { PageTabSectionTileVariant, PageTabs } from './ContentfulPageTabSection.server'

export const LinkBody = styled.div<
  {
    $centerText: boolean
    $gridMobile: boolean
    $gridDesktop: boolean
  } & TypeStyleCssFunctionProps
>`
  ${o => (o.$centerText ? 'text-align: center;' : 'text-align: left;')}
  margin-top: 8px;
  ${TypeStyle.css.bodySmall};
  font-weight: 700;
`

export const PageTabTileImage = styled(ResponsiveImage)<{
  $tileVariantDesktop: PageTabSectionTileVariant
  $tileVariantMobile: PageTabSectionTileVariant
}>`
  ${untilMd} {
    border-radius: ${o => (o.$tileVariantMobile === 'circle' ? '50%' : '6px')};
    display: ${o => o.$tileVariantMobile === 'pill' && 'none'};
  }

  ${fromMd} {
    border-radius: ${o => (o.$tileVariantDesktop === 'circle' ? '50%' : '6px')};
  }
`

export const RawHtmlSection: React.FC<
  Extract<NonNullable<PageTabs[number]['tabSections'][number]>, { __typename: 'RawHtmlSection' }>
> = props => {
  return <div>{String(props)}</div>
}

export const Body = styled(TabList)`
  display: flex;
  gap: 20px;
  ${fromMd} {
    margin-right: ${sectionSideGutterFromMdAndExpanded}px;
  }
`

export const TabTypeStyle = styled(TypeStyle.HeadlineExtraExtraSmall)<{
  $textColor?: string | null
}>`
  color: ${o => o.$textColor};
`

export const Title = styled(TypeStyle.HeadlineSmall)`
  flex: 1;
`
export const TitleBar = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  ${untilMd} {
    gap: 24px;
    margin-left: ${sectionSideGutterUntilMd}px;
  }
  ${fromMd} {
    flex-direction: row;
    margin-left: ${sectionSideGutterFromMdAndExpanded}px;
  }
`

export const StyledTab = styled(Tab)<{ $textColor?: string | null; $isUppercase: boolean }>`
  ${TypeStyle.css.headlineExtraExtraSmall}
  color: ${o => o.$textColor};

  border-style: solid;
  border-width: 0;
  border-bottom-width: ${o => o.theme.link.underline.lineWidth};
  border-color: transparent;
  &[aria-selected='true'] {
    border-color: currentColor;
  }
  height: min-content;
  align-self: center;
  ${o => o.$isUppercase && o.theme.typography.effect.uppercase}
`

export const TileBody = styled.fieldset<{
  $tileVariantDesktop: PageTabSectionTileVariant
  $tileVariantMobile: PageTabSectionTileVariant
}>`
  position: relative;
  ${o =>
    o.$tileVariantMobile === 'pill' &&
    css`
      ${untilMd} {
        background: ${o.theme.color.fill.subtle};
        border-radius: 1em;
        padding: 2px 0 7px;
      }
    `}
`

export const Badge = styled.div`
  z-index: 1;
  border-radius: 4px;
  position: absolute;
  top: ${o => o.theme.spacing(2)};
  left: ${o => o.theme.spacing(2)};
  padding: ${o => o.theme.spacing(1, 3)};
  background-color: ${o => o.theme.color.badge.neutral.fill.on.background};
  box-shadow: 0px 16px 24px -23px ${o => o.theme.color.text.highContrast.on.background + '1A'};
`

export const BadgeText = styled(TypeStyleBodyNano)`
  font-weight: 600;
`

export const StyledTile = styled(Tile)<{ $constrainedTileWidths?: { sm?: number; md?: number } }>`
  ${o =>
    o.$constrainedTileWidths?.sm
      ? `${untilMd} {
          flex-basis: ${o.$constrainedTileWidths?.sm}px;
          width: ${o.$constrainedTileWidths.sm}px;
        }`
      : null}
  ${o =>
    o.$constrainedTileWidths?.md
      ? `${fromMd} {
        flex-basis: ${o.$constrainedTileWidths.md}px;
        width: ${o.$constrainedTileWidths.md}px;
      }`
      : null}
`
